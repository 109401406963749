<template>
    <div v-if="visible" class="floating-window" :style="style" @click="closeWindow">
      <slot></slot>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted,defineProps } from 'vue';
  
  // 使用 defineProps 定义传入的属性
  const props = defineProps({
    width: {
      type: String,
      default: '200px',
    },
    height: {
      type: String,
      default: '100px',
    },
  });
  
  const visible = ref(true);
  const style = ref({});
  
//   const closeWindow = () => {
//     visible.value = false;
//   };
  
  const position = ref({ x: 0, y: 0 });
  const direction = ref({ x: 1, y: 1 });
  const speed = ref(2);
  let animationFrameId;
  
  const updatePosition = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const elementWidth = parseInt(props.width);
    const elementHeight = parseInt(props.height);
  
    position.value.x += direction.value.x * speed.value;
    position.value.y += direction.value.y * speed.value;
  
    if (position.value.x <= 0 || position.value.x + elementWidth >= windowWidth) {
      direction.value.x *= -1;
    }
  
    if (position.value.y <= 0 || position.value.y + elementHeight >= windowHeight) {
      direction.value.y *= -1;
    }
  
    style.value = {
      top: `${position.value.y}px`,
      left: `${position.value.x}px`,
      width: props.width,
      height: props.height,
    };
  
    animationFrameId = requestAnimationFrame(updatePosition);
  };
  
  onMounted(() => {
    animationFrameId = requestAnimationFrame(updatePosition);
  });
  
  onUnmounted(() => {
    cancelAnimationFrame(animationFrameId);
  });
  </script>
  
  <style scoped>
  .floating-window {
    position: fixed;
    background-color: #fff;
    color: #333;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    z-index:99999;
  }
  </style>
  