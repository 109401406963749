import axios from 'axios';

const baseURL = 'https://gold.szzwei.cn';
//  const baseURL = '/api';

const instance = axios.create({
  baseURL: baseURL, // 基础URL
  timeout: 10000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json'
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
 
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;