import { ref } from 'vue';

export default function useTimeDifference() {
  const timeDifference = ref('');
  const difference2 =  ref('');

  const calculateTimeDifference = (timestamp1, timestamp2) => {
    const msInASecond = 1000;
    const msInAMinute = msInASecond * 60;
    const msInAnHour = msInAMinute * 60;
    const msInADay = msInAnHour * 24;
    const msInAWeek = msInADay * 7;
    const difference = Math.abs(timestamp1 - timestamp2);
    (timestamp1 - timestamp2)>0?'':difference2.value='过期';


    if (difference < msInADay) {
      const hours = Math.floor(difference / msInAnHour);
      const minutes = Math.floor((difference % msInAnHour) / msInAMinute);
      const seconds = Math.floor((difference % msInAMinute) / msInASecond);
      timeDifference.value = `${hours}小时${minutes}分${seconds}秒`;
    } else if (difference < msInAWeek) {
      const days = Math.floor(difference / msInADay);
      timeDifference.value = `${days}天`;
    } else {
      timeDifference.value = false;
    }

    return timeDifference.value;
  };

  return {
    calculateTimeDifference,
    timeDifference,
    difference2
  };
}