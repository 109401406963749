<template>
    <div class="not-found">
      <div>
        <h1>404</h1>
      <p>对不起，您访问的页面不存在</p>
      </div>
    </div>
  </template>

  
  <style scoped>
  .not-found {
    text-align: center;
    position: fixed;width: 100vw;height: 100vh;background: #fff;
    display: flex;justify-content: center;align-items: center;
    z-index: 9999999;
  }
  
  .not-found h1 {
    font-size: 150px;
    color: #ff4d4f;
  }
  
  .not-found p {
    font-size: 30px;
    color: #555;
  }
  
  .not-found a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    color: #1890ff;
    text-decoration: none;
    border: 1px solid #1890ff;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .not-found a:hover {
    background-color: #1890ff;
    color: #fff;
  }
  </style>