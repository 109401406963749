import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import api from "@/api/http";

import './assets/styles/reset.css'; // 引入重置样式文件
const app = createApp(App);
const urlParams = new URLSearchParams(window.location.search).get("v");
api.get('/metalMarket/sysTenantBoardConf/getByWebUrl/'+urlParams) // 假设后台接口为 /api/get-title
  .then(response => {
    const title = response.data.logoDesc || '中威软件'; // 如果没有标题，使用默认标题
    document.title = title;
  })
  .catch(() => {
    document.title = '中威软件'; // 请求失败时使用默认标题
  });
app.use(router);
app.mount('#app');
