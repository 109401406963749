<template>
    <div class="watermark">
      <span v-for="item in 100" :key="item" class="watermark-text">中威软件</span>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .watermark {
    position: fixed; /* 固定在页面上，不随滚动条移动 */
    top: 0;
    left: 0;
    width: 150vw; /* 视口宽度 */
    height: 100vh; /* 视口高度 */
    pointer-events: none; /* 允许点击穿透水印层 */
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    z-index: 9999; /* 确保在最上层 */
  }
  
  .watermark-text {
    font-size: 2.5rem;
    margin: 50px;
    color: rgba(134, 134, 134, 0.1);
    transform: rotate(-30deg);
  }
  @media only screen and (max-width: 767px) {
    .watermark-text {
    font-size: 1.5rem;
   
  }
}
  </style>